

















import { Component, Prop, Mixins } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import Cookies from "js-cookie";
import { PortalAccount } from "@/types/mypage";

@Component
export default class extends Mixins(AxiosMixin) {
  /** ログイン者一覧 */
  @Prop({ default: () => ({}) }) private readonly account!: PortalAccount;

  /** ログインタイプ 1:掲載側 2:紹介側 */
  @Prop({ default: 0 }) private readonly login_type!: number;

  /** ポータルを使用する */
  private usePortal(account: PortalAccount) {
    let req: Record<string, unknown> = {};
    switch (this.login_type) {
      case 1:
        req = {
          portal_office_staff_id: account.id,
          portal_office_id: account.organization_id
        };
        break;
      case 2:
        req = {
          portal_request_user_id: account.id,
          organization_id: account.organization_id
        };
        break;
    }
    this.callPortalApi(req, this.login_type);
  }

  /** ポータルのAPIを呼び出す */
  private callPortalApi(req: Record<string, unknown>, use_type: number) {
    this.postJsonCheck(
      window.base_url + "/outer/auth/portal/token",
      req,
      res => {
        window.token = res.data.token;
        Cookies.set("token", res.data.token, {
          domain: window.cookie_domain
        });
        window.reftoken = res.data.ref_token;
        Cookies.set("reftoken", res.data.ref_token, {
          domain: window.cookie_domain
        });
        //けあログっとを開く
        location.href =
          window.portal_frontend_url +
          "/recept?from_ibow=1&use_type=" +
          use_type;
      }
    );
  }
}
