










































































import { Component, Mixins } from "vue-property-decorator";
import { DataTableHeader } from "vuetify/types/index";
import { PortalAccount } from "@/types/mypage";
import AxiosMixin from "@/mixins/axiosMixin";
import Title from "@/components/common/Title.vue";
import Cookies from "js-cookie";

@Component({ components: { Title } })
export default class extends Mixins(AxiosMixin) {
  /** ポータル職員一覧 */
  private portal_staffs: PortalAccount[] = [];

  /** ポータル紹介者一覧 */
  private portal_request_users: PortalAccount[] = [];

  private staff_headers: DataTableHeader[] = [
    {
      text: "利用",
      value: "use",
      align: "start",
      width: "240px",
      cellClass: "text-start"
    },
    {
      text: "職員名",
      value: "name",
      align: "start",
      width: "30%",
      cellClass: "text-start"
    },
    {
      text: "事業所名",
      value: "organization_name",
      align: "start",
      width: "30%",
      cellClass: "text-start"
    },
    {
      text: "権限",
      value: "role_name",
      align: "start",
      width: "40px",
      cellClass: "text-start"
    }
  ];

  private request_user_headers: DataTableHeader[] = [
    {
      text: "利用",
      value: "use",
      align: "start",
      width: "240px",
      cellClass: "text-start"
    },
    {
      text: "スタッフ名",
      value: "name",
      align: "start",
      width: "30%",
      cellClass: "text-start"
    },
    {
      text: "組織名",
      value: "organization_name",
      align: "start",
      width: "30%",
      cellClass: "text-start"
    },
    {
      text: "権限",
      value: "role_name",
      align: "start",
      width: "40px",
      cellClass: "text-start"
    }
  ];

  mounted(): void {
    this.fetch();
  }

  /** データ更新 */
  private fetch() {
    this.postJsonCheck(window.base_url + "/api/portal/list", {}, res => {
      this.portal_staffs = res.data.portal_staffs;
      this.portal_request_users = res.data.portal_request_users;
    });
  }

  /** ケアログを使用する */
  private usePortal(req: Record<string, unknown>, use_type: number) {
    this.postJsonCheck(
      window.base_url + "/outer/auth/portal/token",
      req,
      res => {
        window.token = res.data.token;
        Cookies.set("token", res.data.token, {
          domain: window.cookie_domain
        });
        window.reftoken = res.data.ref_token;
        Cookies.set("reftoken", res.data.ref_token, {
          domain: window.cookie_domain
        });
        //けあログっとを開く
        location.href =
          window.portal_frontend_url +
          "/recept?from_ibow=1&use_type=" +
          use_type;
      }
    );
  }

  /** 紹介側組織を追加する */
  private async addRequestOrganization() {
    if (
      !(await this.$openConfirm(
        "紹介側として新しく組織を作成します。よろしいですか？"
      ))
    ) {
      return;
    }

    location.href =
      window.portal_frontend_url + "/recept/register/request-user";
  }
}
