var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Title',{staticClass:"mb-8",attrs:{"icon":"receipt","title":"所属一覧"}}),_c('v-card-title',{staticClass:"pa-0"},[_vm._v("掲載側職員")]),_c('AppDataTable',{staticClass:"mt-2 pt-4",staticStyle:{"width":"100%"},attrs:{"headers":_vm.staff_headers,"items":_vm.portal_staffs,"noDataText":"掲載側職員が存在しません","isNoFilterButton":"false","isNoTop":"false","isNoFooter":true},scopedSlots:_vm._u([{key:"item.use",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"primary mt-1 mb-1",staticStyle:{"text-transform":"none"},attrs:{"max-width":""},on:{"click":function($event){return _vm.usePortal(
            {
              portal_office_staff_id: item.id,
              portal_office_id: item.organization_id
            },
            1
          )}}},[_vm._v("けあログっとを開く")])]}}],null,true)}),_c('v-row',{staticClass:"mt-8 pa-0",attrs:{"no-gutters":""}},[_c('v-card-title',{staticClass:"pa-0"},[_vm._v("紹介側スタッフ")]),_c('v-spacer'),_c('AppButton',{attrs:{"color":"primary"},on:{"click":_vm.addRequestOrganization}},[_vm._v("紹介組織を追加する")])],1),_c('AppDataTable',{staticClass:"mt-2 pt-4",staticStyle:{"width":"100%"},attrs:{"headers":_vm.request_user_headers,"items":_vm.portal_request_users,"noDataText":"紹介側スタッフが存在しません","isNoFilterButton":"false","isNoTop":"false","isNoFooter":true},scopedSlots:_vm._u([{key:"item.use",fn:function(ref){
          var item = ref.item;
return [_c('v-btn',{staticClass:"primary mt-1 mb-1",staticStyle:{"text-transform":"none"},attrs:{"max-width":""},on:{"click":function($event){return _vm.usePortal(
            {
              portal_request_user_id: item.id,
              organization_id: item.organization_id
            },
            2
          )}}},[_vm._v("けあログっとを開く")])]}}],null,true)}),_c('AppLoadingDialog',{staticStyle:{"z-index":"1000"},attrs:{"loading":_vm.loading}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }